.account {
  font-size: 0.5em;
}

.name {
}

td {
  padding: 1rem 0 1rem 1rem;
}
