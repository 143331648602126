.logs-session-player-role-overview-graph-container {
  display: flex;
  padding: 0;
  margin: 0;
  height: 1rem;
}

.logs-session-player-role-overview-graph-element {
  flex: 1 1 0.5rem;
  padding: 0;
  margin: 0;
  height: 100%;
}
